<template>
  <base-modal-view v-if="dealer" :title="dealer.first_name + ' ' + dealer.last_name" @cancel="cancelClicked">
    <form @submit.prevent="submitClicked" class="flex-column">
      <label class="text-field-title margin-bottom-025 margin-top">PIN</label>
      <input
        v-model="pin"
        class="text-field"
        type="password"
        placeholder="Enter your PIN"
        name=""
        id=""
        required
        ref="pinField"
        autofocus
      />
      <primary-button class="margin-top-1-05" title="Submit" :disabled="disabled" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

export default {
  name: "PinModal",
  components: { PrimaryButton, BaseModalView },
  props: ["dealer", "disabled"],
  data: function () {
    return {
      pin: ""
    };
  },
  methods: {
    submitClicked: function () {
      this.$emit("submitted", this.pin);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    focusPinField: function () {
      let app = this;
      setTimeout(() => {
        app.$nextTick(() => {
          app.$refs.pinField?.focus();
        });
      }, 225);
    }
  },
  mounted: function () {
    this.focusPinField();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
