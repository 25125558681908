<template>
  <div>
    <div id="switch-dealer-page">
      <h1 class="h1-title">Choose Dealer</h1>
      <div class="results-table-container margin-top-2">
        <table class="results-table" v-for="(characterDict, index) in getSortedDealers()" v-bind:key="index">
          <tr class="header">
            <th>{{ characterDict.character }}</th>
          </tr>
          <tr
            class="data-row selectable"
            v-for="dealer in characterDict.dealers"
            v-bind:key="dealer.id"
            v-on:click="dealerSelected(dealer)"
          >
            <td>{{ dealer.first_name }} {{ dealer.last_name }}</td>
          </tr>
        </table>
      </div>

      <pin-modal
        v-if="dealer"
        class="animate"
        :dealer="dealer"
        @cancel="dealerCleared"
        @submitted="pinSubmitted"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { verifyPIN } from "../../api/Dealer.js";
import PinModal from "../../components/Modals/v2/PinModal.vue";

export default {
  name: "SwitchDealer",
  components: { PinModal },
  data: function () {
    return {
      dealer: null,
      disabled: false
    };
  },
  methods: {
    getSortedDealers: function () {
      var toReturn = [];
      var sortedDealers = this.dealers
        .filter(dealer => dealer.is_active)
        .sort((dealer1, dealer2) => dealer1.last_name.localeCompare(dealer2.last_name));

      for (var i = 0; i < sortedDealers.length; i++) {
        var dealer = sortedDealers[i];

        if (dealer.last_name) {
          let character = dealer.last_name.charAt(0);
          let dealers = sortedDealers.filter(
            dealer => dealer.last_name.charAt(0).toUpperCase() == character.toUpperCase()
          );
          let dict = {
            character: character,
            dealers: dealers
          };

          toReturn.push(dict);
          i += dealers.length - 1;
        } else {
          let character = "*";
          let dealers = sortedDealers.filter(dealer => !dealer.last_name);
          let dict = {
            character: character,
            dealers: dealers
          };

          toReturn.push(dict);
          i += dealers.length - 1;
        }
      }

      return toReturn;
    },
    dealerSelected: function (dealer) {
      this.dealer = dealer;
    },
    dealerCleared: function () {
      this.dealer = null;
    },
    pinSubmitted: function (pin) {
      this.disabled = true;
      verifyPIN(this.dealer, pin)
        .then(response => {
          if (response?.success) {
            this.setCurrentDealer({ dealer: this.dealer, pin: pin });
            let next = this.$route.query.next;
            if (next) {
              window.open(next, "_blank");
            }
            this.$router.push({ name: "Search" });
          } else {
            this.addError("Couldn't verify PIN");
          }
        })
        .catch(error => {
          this.addError(error);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    ...mapActions({
      fetchDealers: "dealershipStore/fetchDealers",
      setCurrentDealer: "dealershipStore/setCurrentDealer",
      setDealerToken: "authStore/setDealerToken",
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapGetters({
      dealers: "dealershipStore/currentDealers",
      dealership: "dealershipStore/currentDealership"
    })
  },
  mounted: function () {
    let app = this;
    this.$nextTick(() => {
      app.fetchDealers();
    });
    if (this.dealership) {
      const favicon = document.getElementById("favicon");
      if (this.dealership.is_tender_account) {
        favicon.href = "/tender-ico.png";
      } else if (this.dealership.is_buyers_account) {
        favicon.href = "/buyer-ico.png";
      } else {
        favicon.href = "/favicon.ico";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#switch-dealer-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
